<template>
  <div class="transportation-accounts-vehicles">
    <div v-if="$router.currentRoute.name === 'transportation-accounts-vehicles'">
      <div class="layout justify-start">
        <v-btn :to="{name: 'transportation-accounts-vehicles-realtime', params: { accountId }}" class="mr-2">
          <v-icon small>fal fa-location-circle</v-icon> &nbsp; Realtime Map
        </v-btn>
      </div>
      <v-layout row wrap>
        <v-flex md6 sm3 pa-2>
          <form @submit.prevent="searchVehicles">
            <v-text-field
                solo
                v-model="searchKeywords"
                append-icon="fal fa-search"
                :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
                :disabled="apiInProgress"
                type="search"
                placeholder="Search by licence plate, equipment device id/inventory id"
                @click:append="searchVehicles"
            />
          </form>
        </v-flex>
        <v-flex md2 sm3 pa-2>
          <v-select
              clearable
              :items="transportTypes"
              label="Vehicle Type"
              :value="selectedTransportType"
              @change="onFilterByTransportType"
          />
        </v-flex>
        <v-flex md2 sm3 pa-2>
          <v-select
              clearable
              :items="agencies"
              label="Agency"
              :value="selectedAgency"
              @change="onFilterByAgency"
          />
        </v-flex>
        <v-flex md2 sm3 pa-2>
          <v-btn block color="primary" v-if="hasUserType('admin')" :disabled="apiInProgress" :to="{ name: 'transportation-accounts-vehicle', params: {vehicleId: 0} }"><v-icon>fal fa-plus</v-icon> &nbsp; Create Vehicle</v-btn>
        </v-flex>
      </v-layout>
      <v-data-table
          :headers="headers"
          :items="vehicles"
          :pagination.sync="pagination"
          :total-items="totalVehicles"
          :loading="apiInProgress"
          :rows-per-page-items="[10, 25, 50]"
          @update:pagination="onPagination"
          class="elevation-1"
          must-sort
      >
        <template v-slot:items="props">
          <tr :class="props.item.isEditable ? 'tr--pointer' : ''" @click.stop="loadVehicle(props.item)">
            <td :title="props.item.licensePlateNumber">
              <v-text-field
                  class="text-xs-left text-field--flat pb1"
                  :value="props.item.licensePlateNumber"
                  readonly
                  hide-details
              />
            </td>
            <td class="text-xs-left">
              <v-icon small class="pr-4" :title="`Has ${props.item.transportationProfileRoutesCount} route(s)`" v-if="props.item.transportationProfileRoutesCount">
                fa fa-map-marker-check
              </v-icon>
              <v-icon small title="Uses routes" v-if="!props.item.isRouteFree">
                fa fa-check-circle
              </v-icon>
              <v-icon small title="Route free" v-if="props.item.isRouteFree">
                fal fa-circle
              </v-icon>
            </td>
            <td class="text-xs-left" :title="props.item.equipmentTitle">
              <v-icon small v-if="props.item.hasEquipment">
                fas fa-tablet-android-alt
              </v-icon>
            </td>
            <td class="text-xs-left" :title="props.item.currentDriverLabel + ' ' + props.item.currentAssistantLabel">
              <v-text-field
                  class="text-xs-left text-field--flat pb1"
                  :value="props.item.currentDriverLabel"
                  readonly
                  hide-details
              />
              <v-text-field
                  v-show="props.item.currentAssistantLabel"
                  class="text-xs-left text-field--flat"
                  :value="props.item.currentAssistantLabel"
                  readonly
                  hide-details
              />
            </td>
            <td class="text-xs-left">
              {{ props.item.transportType }}
            </td>
            <td class="text-xs-left">{{ props.item.agencyId }}</td>
            <td class="text-xs-left">{{ props.item.dateCreatedLabel }}</td>
            <td class="text-xs-left">{{ props.item.dateUpdatedLabel }}</td>
            <td class="justify-center layout px-0">
              <v-btn flat icon title="Delete vehicle" :disabled="!props.item.isEditable" :loading="props.item.isDeleteInProgress"  @click.stop="deleteVehicle(props.item)">
                <v-icon small>
                  fas fa-trash-alt
                </v-icon>
              </v-btn>
              <router-link @click.native="$event.stopImmediatePropagation()" :v-show="props.item.isEditable" :to="{ name: 'transportation-accounts-vehicle', params: { vehicleId: props.item.id } }">
                <v-btn flat icon title="View vehicle">
                  <v-icon small>
                    fas fa-bus
                  </v-icon>
                </v-btn>
              </router-link>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <router-view
      :force-root-view-refresh="forceRootViewRefresh"
      :signed-in-user="signedInUser"
      :selected-account="selectedAccount"
      :set-as-selected-account="setAsSelectedAccount"
      :has-user-type="hasUserType"
      :transport-types="transportTypes"
      :agencies="agencies"
      :has-transportation-role="hasTransportationRole">
    </router-view>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
