import _uniq from 'lodash.uniq'
import { formatDate, formatTime } from '@smarttransit/common'
import { generateKeywordsQueryForOr } from '@smarttransit/common-client'
import { addAlert } from '../../../utilities/helpers'
import { findTransportationEquipment } from '../../../services/transportation-equipment-service'

import {
  deleteTransportationProfile,
  findTotalTransportationProfiles,
  findTransportationProfiles
} from '../../../services/transportation-profiles-service'

import { findTransportationOwners } from '../../../services/transportation-owners-service'
import { findTransportationOwnerUsers } from '../../../services/transportation-owner-users-service'

export default {
  name: 'transportation-accounts-vehicles',
  props: {
    signedInUser: Object,
    selectedAccount: Object,
    setAsSelectedAccount: Function,
    hasUserType: Function,
    hasTransportationRole: Function,
    forceRootViewRefresh: Function
  },
  data () {
    return {
      searchKeywords: null,
      selectedAgency: null,
      selectedTransportType: null,
      apiInProgress: false,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'dateUpdated'
      },
      headers: [],
      totalVehicles: 0,
      vehicles: [],
      agencies: [
        { text: 'SmartTransit Ghana', value: 'STRANSITGHA', metadata: { countryId: 'GHA' } },
        { text: 'GPRTU', value: 'GPRTU', metadata: { countryId: 'GHA' } },
        { text: 'PROTOA', value: 'PROTOA', metadata: { countryId: 'GHA' } }
      ],
      transportTypes: [
        { text: 'SmartTransit Mini Bus', value: 'smarttransit_minibus' },
        { text: 'SmartTransit Bus', value: 'smarttransit_bus' },
        { text: 'Minibus', value: 'minibus' }
      ]
    }
  },
  computed: {
    accountId () {
      if (this.$props.selectedAccount) {
        return this.$props.selectedAccount.id
      }
      return 0
    }
  },
  mounted: function () {
    function onRouterLoad () {
      this.headers = [
        {
          text: 'Licence Plate',
          align: 'left',
          sortable: true,
          value: 'licensePlateNumber'
        },
        {
          text: 'Uses Routes',
          align: 'left',
          sortable: true,
          value: 'isRouteFree'
        },
        {
          text: 'Equipment',
          align: 'left',
          sortable: true,
          value: 'transportationEquipmentId'
        },
        {
          text: 'Driver/Assistant',
          align: 'left',
          sortable: false
        },
        {
          text: 'Vehicle Type',
          align: 'left',
          sortable: true,
          value: 'transportType'
        },
        {
          text: 'Agency',
          align: 'left',
          sortable: true,
          value: 'agencyId'
        },
        {
          text: 'Date Created',
          align: 'left',
          sortable: true,
          value: 'dateCreated'
        },
        {
          text: 'Date Updated',
          align: 'left',
          sortable: true,
          value: 'dateUpdated'
        },
        { text: 'Actions', value: 'licencePlateNumber', sortable: false }
      ]
    }
    if (this.$router.currentRoute.name === 'transportation-accounts-vehicles') {
      this.$nextTick(onRouterLoad.bind(this))
      this.searchVehicles()
    }
  },
  methods: {
    async getTransportationProfileIdsFromRelations (accountId) {
      const [transportationEquipment, transportationOwners, transportationOwnerUsers] = await Promise.all([
        findTransportationEquipment({
          fields: { id: true, inventoryId: true },
          where: { or: generateKeywordsQueryForOr(this.searchKeywords, ['inventoryId', 'deviceId']) },
          join: 'transportationProfile',
          include: 'transportationProfile'
        }),
        findTransportationOwners({
          fields: { id: true },
          where: { and: [
            { id: accountId || { neq: null } },
            { or: generateKeywordsQueryForOr(this.searchKeywords, ['companyName', 'contactName', 'contactEmail', 'contactPhone']) }
          ] },
          join: 'transportationOwnerProfiles',
          include: {
            relation: 'transportationOwnerProfiles',
            scope: { fields: { id: true, transportationProfileId: true, transportationOwnerId: true } }
          }
        }),
        findTransportationOwnerUsers({ filter: {
          where: { transportationOwnerId: accountId || { neq: null } },
          join: {
            relation: 'stUser',
            scope: { where: { or: generateKeywordsQueryForOr(this.searchKeywords, ['firstName', 'lastName', 'email', 'phone']) } }
          },
          include: [{
            relation: 'transportationDriverProfile',
            scope: { fields: { id: true } }
          }, {
            relation: 'transportationAssistantProfile',
            scope: { fields: { id: true } }
          }]
        } })
      ])
      let uniqueIncludeIds = []
      if (transportationEquipment.length) {
        uniqueIncludeIds = transportationEquipment.map(o => o.transportationProfile.id)
      }
      if (transportationOwners.length) {
        transportationOwners.forEach((transportationOwner) => {
          uniqueIncludeIds = uniqueIncludeIds.concat(transportationOwner.transportationOwnerProfiles.map(o => o.transportationProfileId))
        })
      }
      if (transportationOwnerUsers.length) {
        transportationOwnerUsers.forEach((transportationOwnerUser) => {
          if (transportationOwnerUser.transportationDriverProfile) {
            uniqueIncludeIds.push(transportationOwnerUser.transportationDriverProfile.id)
          } else if (transportationOwnerUser.transportationAssistantProfile) {
            uniqueIncludeIds.push(transportationOwnerUser.transportationAssistantProfile.id)
          }
        })
      }
      return uniqueIncludeIds ? _uniq(uniqueIncludeIds) : uniqueIncludeIds
    },
    async searchVehicles () {
      try {
        const { hasUserType, hasTransportationRole } = this.$props
        const { sortBy, descending, page, rowsPerPage } = this.pagination
        const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage
        let filter = {
          limit: rowsPerPage,
          offset,
          where: {},
          include: [{ currentDriverObj: 'stUser' }, 'currentAssistantObj', 'transportationProfileRoutes', 'transportationEquipment']
        }
        if (sortBy) {
          if (sortBy === 'transportationEquipmentId') {
            filter.order = [`${sortBy} ${descending ? 'DESC' : 'ASC'}`, 'dateUpdated DESC']
          } else {
            filter.order = `${sortBy} ${descending ? 'DESC' : 'ASC'}`
          }
        }
        const accountId = Number(this.$router?.currentRoute?.params?.accountId || '0')
        if (accountId) {
          filter.join = {
            relation: 'transportationOwnerProfile',
            scope: { where: { transportationOwnerId: accountId } }
          }
        }

        if (this.selectedAgency) {
          filter.where.agencyId = this.selectedAgency
        }
        if (this.selectedTransportType) {
          filter.where.transportType = this.selectedTransportType
        }
        if (this.searchKeywords) {
          filter.where.or = generateKeywordsQueryForOr(this.searchKeywords, ['licensePlateNumber'])
          const uniqueIncludeIds = await this.getTransportationProfileIdsFromRelations(accountId)
          if (uniqueIncludeIds.length) {
            filter.where.or.push({ id: { inq: uniqueIncludeIds } })
          }
        }
        this.apiInProgress = true
        const [totalVehicles, vehicles] = await Promise.all([
          findTotalTransportationProfiles({
            where: filter.where,
            join: filter.join
          }),
          findTransportationProfiles(filter)
        ])
        this.apiInProgress = false
        this.totalVehicles = totalVehicles.count
        vehicles.forEach((o) => {
          o.hasEquipment = !!o.transportationEquipmentId
          o.equipmentTitle = ''
          if (o.hasEquipment) {
            o.equipmentTitle = o.transportationEquipment.inventoryId ? o.transportationEquipment.inventoryId : o.transportationEquipment.deviceId
          }
          o.currentDriverLabel = o.currentDriverObj?.stUser ? `${o.currentDriverObj.stUser.firstName || ''} ${o.currentDriverObj.stUser.lastName || ''}` : ''
          o.currentAssistantLabel = o.currentAssistantObj?.stUser ? `Assistant: ${o.currentAssistantObj.stUser.firstName || ''} ${o.currentAssistantObj.stUser.lastName || ''}` : ''
          o.dateCreatedLabel = `${formatDate(o.dateCreated)}, ${formatTime(o.dateCreated)}`
          o.dateUpdatedLabel = o.dateUpdated ? `${formatDate(o.dateUpdated)}, ${formatTime(o.dateUpdated)}` : ''
          o.isEditable = hasUserType('staff') || hasTransportationRole('ADMIN')
          o.transportationProfileRoutesCount = o.transportationProfileRoutes ? o.transportationProfileRoutes.length : 0
        })
        this.vehicles = vehicles
      } catch (err) {
        this.apiInProgress = false
        addAlert({
          message: `Error finding vehicles: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
          type: 'error'
        })
      }
    },
    onFilterByAgency (val) {
      this.selectedAgency = val
      this.searchVehicles()
    },
    onFilterByTransportType (val) {
      this.selectedTransportType = val
      this.searchVehicles()
    },
    onPagination () {
      if (this.totalVehicles) {
        this.searchVehicles()
      }
    },
    loadVehicle (vehicle) {
      if (vehicle.isEditable) {
        this.$router.push({ name: 'transportation-accounts-vehicle', params: { vehicleId: vehicle.id } })
      }
    },
    deleteVehicle (vehicle) {
      if (confirm(`Confirm deleting vehicle: ${vehicle.licensePlateNumber} and any included routes`)) {
        this.apiInProgress = true
        deleteTransportationProfile({ id: vehicle.id }).then(() => {
          return this.searchVehicles()
        }).catch((err) => {
          addAlert({
            message: `Error deleting vehicle: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
            type: 'error'
          })
        }).finally(() => {
          this.apiInProgress = false
        })
      }
    }
  }
}
